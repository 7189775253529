import styled from "styled-components";

const FeaturesCardStyleWrapper2 = styled.article`
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  min-height: 250px;
  padding: 35px;
  padding-top: 75px; // Adjust based on your design needs
  border-radius: 15px;
  position: relative;

  img {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: auto;
    z-index: 1;
  }

  h3 {
    font-family: "Bubblegum Sans", sans-serif;
    color: #ff69b4; /* Pink text color */
    font-size: 32px;
    line-height: 30px;
    font-weight: 700;
    text-align: center;
    margin: 0 auto; /* Ensures the title is centered if needed */
    padding-top: 40px;
    z-index: 2;
    -webkit-text-stroke: 0.1px #ffffff; /* Adds a white stroke for WebKit/Blink browsers */
    text-stroke: 0.1px #ffffff; /* Standard property if available, though not widely supported */
  }

  p {
    font-family: "Bubblegum Sans", sans-serif;
    font-weight: 500;
    font-size: 26px;
    color: #ffe5b4;
    line-height: 30px;
    margin: 0 auto; // Centers the paragraph container
    text-align: center; // Ensures text within the paragraph is centered
    max-width: 300px;
    padding-top: 15px;
    width: 100%; // Ensures the paragraph uses the max-width for centering
  }

  // Existing media queries as you defined
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    h3 {
      font-size: 26px;
      line-height: 28px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    padding: 27px;
    padding-top: 75px; // Adjust if the image positioning needs change

    h3 {
      font-size: 26px;
    }
  }

  @media only screen and (max-width: 767px) {
    p {
      max-width: 100%; // Allows the paragraph to expand in smaller screens
    }
  }

  @media (max-width: 375px) {
    padding: 30px;
    padding-top: 75px; // Adjust if the image positioning needs change

    h3 {
      font-size: 26px;
      line-height: 29px;
    }
  }
`;

export default FeaturesCardStyleWrapper2;
