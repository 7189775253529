import { useModal } from "../../../../utils/ModalContext";
import { useEffect, useState } from "react";
import { FaDiscord, FaTelegram, FaTwitter, FaWallet } from "react-icons/fa";
import { MdNotes } from "react-icons/md";
import Button from "../../../../common/button";
import NavWrapper from "./Header.style";
import MobileMenu from "../mobileMenu/MobileMenu";
import logo from "../../../../assets/images/logox.png";
import { isMetaMaskInstalled } from "../../../../config";
import Dropdown from "react-bootstrap/Dropdown";

const Header = () => {
  const [isMobileMenu, setMobileMenu] = useState(false);
  const handleMobileMenu = () => {
    setMobileMenu(!isMobileMenu);
  };

  const substr = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) : str;
  };

  useEffect(() => {
    const header = document.getElementById("navbar");
    const handleScroll = window.addEventListener("scroll", () => {
      if (window.pageYOffset > 50) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    });

    return () => {
      window.removeEventListener("sticky", handleScroll);
    };
  }, []);

  const isAirdropActive = false;
  const isCrateActive = false;

  const [mainMenuItems, setMainMenuItems] = useState([
    { label: "Home", href: "#home" },
    { label: "Airdrop", href: "#airdrop" },
  ]);

  const initialMainMenuItems = [
    { label: "Home", href: "#home" },
    { label: "Airdrop", href: "#airdrop" },
  ];

  const [subMenuItems, setSubMenuItems] = useState([
    { label: "Tokenomics", href: "#tokenomics" },
    { label: "Buy", href: "#buy" },
    { label: "Our Partner", href: "#our-partner" },
    { label: "Social", href: "#social" },
  ]);

  useEffect(() => {
    // We start with our full list of submenu items
    let newSubMenuItems = [
      { label: "Tokenomics", href: "#tokenomics" },
      { label: "Buy", href: "#buy" },
      { label: "Our Partner", href: "#our-partner" },
      {
        label: "View Contract",
        //href: "https://bscscan.com/address/0x2635E4a08c2Ad3f2dAc194B6740211Ac22792ef5",
      },
      { label: "Social", href: "#social" },
    ];

    // And an empty list of dynamic items
    let dynamicItems = [];

    // If there is only one dynamic item, we add one more item from the submenu to the main menu
    if (dynamicItems.length === 1) {
      dynamicItems.push(newSubMenuItems[0]);
      newSubMenuItems = newSubMenuItems.slice(1);
    }

    // If there are no dynamic items, we add two items from the submenu to the main menu
    if (dynamicItems.length === 0) {
      dynamicItems.push(newSubMenuItems[0], newSubMenuItems[1]);
      newSubMenuItems = newSubMenuItems.slice(2);
    }

    // Finally, we set our main menu items as a combination of our initial items, plus any dynamic items
    // and our submenu items to the updated list
    setMainMenuItems([...initialMainMenuItems, ...dynamicItems]);
    setSubMenuItems(newSubMenuItems);
  }, [isAirdropActive, isCrateActive]);

  return (
    <NavWrapper className="bithu_header" id="navbar">
      <div className="container">
        {/* Main Menu Start */}
        <div className="bithu_menu_sect">
          <div className="bithu_menu_left_sect">
            <div className="logo">
              <a href="/">
                <img src={logo} alt="bithu nft logo" />
              </a>
            </div>
          </div>
          <div className="bithu_menu_right_sect bithu_v1_menu_right_sect">
            <div className="bithu_menu_list">
              <ul>
                {mainMenuItems.map((item) => (
                  <li>
                    <a href={item.href}>{item.label}</a>
                  </li>
                ))}
                <li className="submenu">
                  <a href="# ">More +</a>
                  <div className="sub_menu_sect">
                    <ul className="sub_menu_list">
                      {subMenuItems.map((item) => (
                        <li>
                          <a href={item.href}>{item.label}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            <div className="bithu_menu_btns">
              <button className="menu_btn" onClick={() => handleMobileMenu()}>
                <MdNotes />
              </button>
              <a
                href="https://twitter.com/Blinky_Solana"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button sm variant="outline" className="join_btn">
                  <FaTwitter /> Twitter
                </Button>
              </a>
              <a
                href="https://t.me/blinkysol"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button sm variant="outline" className="join_btn">
                  <FaTelegram /> Telegram
                </Button>
              </a>
            </div>
          </div>
        </div>
        {/* <!-- Main Menu END --> */}
        {isMobileMenu && <MobileMenu mobileMenuhandle={handleMobileMenu} />}
      </div>
    </NavWrapper>
  );
};

export default Header;
