import TeamMemberStyleWrapper from "./TeamMember.style";
const TeamMember = ({ avatar, name, designation, url }) => {
  // Function to handle click event
  const handleClick = () => {
    window.open(url, "_blank");
  };

  return (
    <TeamMemberStyleWrapper>
      <div
        className="bithu_team_card"
        onClick={handleClick}
        style={{ cursor: "pointer" }}
      >
        <div className="team_member_img">
          <img src={avatar} alt="bithu nft team avatar" />
        </div>
        <h3>{name}</h3>
        <h4>{designation}</h4>
      </div>
    </TeamMemberStyleWrapper>
  );
};

export default TeamMember;
