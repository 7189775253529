import { useModal } from "../../../../utils/ModalContext";
import Counter from "../../../../common/counter";
import Particle from "../../../../common/particle/v1";
import Button from "../../../../common/button";

import data from "../../../../assets/data/particle/bannerV2Particles";
import BannerStyleWrapper from "./Banner.style";
import { useEffect, useState } from "react";
import { totalMintCount } from "../../../../utils/web3mint";

const Banner = () => {
  const { mintModalHandle, connectWalletModalHanlde, account } = useModal();

  const [remaining, setRemaining] = useState(0);

  useEffect(() => {
    const calculateRemainingItems = async () => {
      let totaltMintedItems = await totalMintCount();
      setRemaining(totaltMintedItems);
    };

    calculateRemainingItems();
  }, []);

  return (
    <BannerStyleWrapper className="bithu_v2_baner_sect" id="airdrop">
      <div className="container">
        <div className="bithu_v2_baner_content">
          <h2>
            {" "}
            <span className="banner-emot">🎉</span>$BLINKY AIRDROP IS{" "}
            <span>Live !</span>
          </h2>
          <div className="bithu_v2_baner_buttons text-center">
            <Button
              lg
              variant="mint"
              onClick={() =>
                window.open(
                  "https://docs.google.com/forms/d/e/1FAIpQLSdeDy-JvgeWuzcfBw_VQMj-LML9fOuiv6NvNnDQtO0Cz9ZDGg/viewform?usp=sf_link",
                  "_blank"
                )
              }
            >
              {" "}
              Join Now
            </Button>
          </div>

          <Particle className="v2_baner_stars" particles={data} />
        </div>
      </div>
    </BannerStyleWrapper>
  );
};

export default Banner;
