import styled from "styled-components";

const SectionTitleWrapper = styled.div`
  position: relative;
  z-index: 1;

  .section-title-right-override {
    text-align: right !important;
  }

  h2 {
    font-family: "Great Vibes", cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #ff69b4;
    margin-bottom: 15px;
    img {
      margin-left: 10px;
      margin-right: 10px;
      margin-top: -1px;
    }

    .shape_left {
      margin-right: 9px;
    }

    span {
      margin-left: 9px;
    }
  }

  h3 {
    font-family: "Bubblegum Sans", sans-serif;
    color: #ffffff; // Keeps the text color white
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 67px;
    margin-bottom: 0;
    -webkit-text-stroke: 1px #ff69b4; // Adds a pink stroke
    text-stroke: 1px #ff69b4; // Standard property if available
    text-shadow: -1px -1px 0 #ff69b4, 1px -1px 0 #ff69b4, -1px 1px 0 #ff69b4,
      1px 1px 0 #ff69b4;
  }

  @media only screen and (max-width: 1199px) {
    h2 {
      margin-bottom: 10px;
    }

    h3 {
      font-size: 70px;
      line-height: 1.35;
    }
  }

  @media only screen and (max-width: 991px) {
    h2 {
      margin-bottom: 5px;
    }

    h3 {
      font-size: 50px;
    }
  }

  @media only screen and (max-width: 767px) {
    h2 {
      font-size: 16px;

      span {
        height: 10px;
      }
    }

    h3 {
      font-size: 50px;
      line-height: 45px;
    }
  }

  @media only screen and (max-width: 575px) {
    h3 {
      font-size: 50px;
    }
  }
`;

export default SectionTitleWrapper;
