import { useModal } from "../../../../utils/ModalContext";
import Counter from "../../../../common/counter";
import Button from "../../../../common/button";
import BannerV1Wrapper from "./Banner.style";
import { FaCopy } from "react-icons/fa"; // This is assuming you're using React Icons for the copy icon
import { toast } from "react-toastify";

import logoset from "../../../../assets/images/logoset.png";
import CountdownTimer from "../../../../common/countdown/Timer";
import CountdownTimerSmall from "../../../../common/countdown/TimerSmall";

const Banner = () => {
  const contractAddress = "675kPX9MHTjS2zt1qfr1NYHuzeLXfQM9H24wFSUt1Mp8";
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(contractAddress).then(() => {
      toast.success("Contract address copied to clipboard!");
    });
  };
  return (
    <BannerV1Wrapper id="home">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="bithu_v1_baner_left">
              <img src={logoset} alt="Logo Set" style={{ width: "200px" }} />
              <h3>The Prettiest Lady Pup on the Solana Blockchain</h3>
              <div className="bithu_v2_baner_buttons text-center">
                <Button
                  lg
                  variant="mint-neo"
                  onClick={() =>
                    window.open(
                      "https://www.pinksale.finance/solana/launchpad/78hBwGrfHMG1TcXNqXd3LmdUHsvTr6SNDJXZV75dUHSf",
                      "_blank"
                    )
                  }
                >
                  {" "}
                  Join Presale
                </Button>
              </div>
            </div>
          </div>
        </div>
        {/* <div
          className="contract-address-container"
          style={{ marginTop: "200px", textAlign: "center" }}
        >
          <h3>CA: {contractAddress}</h3>
          <button onClick={handleCopyToClipboard}>
            <FaCopy size="1em" /> {}
          </button>
        </div>
         */}
      </div>
    </BannerV1Wrapper>
  );
};

export default Banner;
