import styled from "styled-components";
import sectionBgImage from "../../../../assets/images/bg/blinky_banner_3.png";

const AboutStyleWrapper = styled.section`
  background: url(${sectionBgImage});
  background-size: cover;
  background-position: center left;
  background-repeat: no-repeat;
  background-color: #030a0f;
  width: 100%;
  height: 100;
  min-height: 900px;
  p {
    margin-bottom: 0;
  }

  .v1_about_us_right_sect {
    background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent dark background */
    padding: 20px; /* Adds padding around the container */
    border-radius: 15px; /* Optional: Adds rounded corners to the container */
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center; /* Centers the text in the container */
    width: 50%;
  }

  .v1_about_us_right_sect h2,
  .v1_about_us_right_sect h3 {
    font-family: "Bubblegum Sans", sans-serif;
    color: #ff69b4; /* Pink color */
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6); /* Enhanced text shadow for better visibility */
    letter-spacing: 1px; /* Increased letter spacing for a stylish look */
    /* Adjust font-weight and size as needed */
  }

  /* Additional styles for specific elements if needed */
  .v1_about_us_right_sect p {
    font-family: "Bubblegum Sans", sans-serif;
    color: #ff5733;
    font-size: 28px;
  }

  /* Adjust this selector based on the actual structure and classes of SectionTitle */
  .sectionTitle,
  .sectionSubtitle {
    /* Styles for the title and subtitle if they need specific adjustments */
  }
  in

  /* right */
  .v1_about_us_right_text {
    p {
      color: #ff5733; /* Vibrant orange-red color */
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 40px;
      color: rgba(255, 255, 255, 0.8);
      padding-bottom: 150px;
      margin-bottom: 150px;
    }
  }

  .imageContainer {
    display: flex;
    img {
      width: 200px; // you can adjust this percentage to your liking
      height: auto; // maintain the aspect ratio
      margin-right: 10px;
    }
  }

  .v1_about_us_left_sect {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 560px;
    width: 100%;
    height: 100%; /* Or any other value */
  }

  .v1_about_us_img1 {
    max-width: 230px;
    height: 230px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin: auto;
    text-align: center;
    background: rgba(255, 255, 255, 0.1);
  }

  .v1_about_us_img_card1 {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(20px);
    height: 328px;
    width: 270px;
    padding: 24px;
    position: absolute;
    top: 50px;
    left: 30px;
    transform: rotate(-15deg);
    z-index: 1;
  }

  .v1_about_us_img1_shapes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 18px;
  }

  .v1_about_us_img1_shapes_left {
    .shape_1 {
      background: rgba(255, 255, 255, 0.1);
      height: 15px;
      width: 115px;
    }

    .shape_2 {
      margin-top: 10px;
      background: rgba(255, 255, 255, 0.1);
      height: 8px;
      width: 75px;
    }
  }

  .v1_about_us_img1_shapes_right {
    .shape_3 {
      background: rgba(255, 255, 255, 0.1);
      height: 28px;
      width: 28px;
      border-radius: 50%;
    }
  }

  .v1_about_us_img_card2 {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(20px);
    height: 279px;
    width: 230px;
    padding: 20px;
    position: absolute;
    top: 150px;
    right: 20px;
    transform: rotate(15deg);
    z-index: 0;
  }

  .v1_about_us_img2 {
    max-width: 195px;
    height: 195px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    text-align: center;
    background: rgba(255, 255, 255, 0.1);
  }

  .v1_about_us_img2_shapes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
  }

  .v1_about_us_img2_shapes_left {
    .shape_1 {
      background: rgba(255, 255, 255, 0.1);
      height: 13px;
      width: 98px;
    }

    .shape_2 {
      margin-top: 10px;
      background: rgba(255, 255, 255, 0.1);
      height: 7px;
      width: 64px;
    }

    .shape_3 {
      background: rgba(255, 255, 255, 0.1);
      height: 24px;
      width: 24px;
      border-radius: 50%;
    }
  }

  /* list */

  .about_us_text_card_sect {
    margin-top: 120px;
  }
  .about_us_text_card {
    background: rgba(255, 255, 255, 0.05);
    width: 100%;
    min-height: 220px;
    padding: 35px;

    h3 {
      font-family: "Bubblegum Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 40px;
      color: #000000;
      img {
        padding-right: 10px;
      }

      span {
        margin-right: 13px;
      }
    }

    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      max-width: 289px;
      color: rgba(255, 255, 255, 0.8);
    }
  }

  @media only screen and (max-width: 1199px) {
    .v1_about_us_right_text {
      margin-top: 5px;
    }

    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      max-width: 800px;
      color: rgba(255, 255, 255, 0.8);
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .v1_about_us_img_card1 {
      left: 10px;
      top: 75px;
      height: 295px;
      width: 240px;
    }

    .v1_about_us_img_card2 {
      height: 255px;
      width: 205px;
    }

    .v1_about_us_img2 {
      height: 170px;
    }
    .v1_about_us_img1 {
      height: 190px;
    }

    .about_us_text_card {
      h3 {
        font-size: 20px;
        line-height: 40px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        span {
          max-width: 40px;
        }
      }

      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: rgba(255, 255, 255, 0.8);
        padding-top: 18px;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    padding-top: 65px;

    .about_us_text_card_sect {
      margin-top: 60px;

      &.md-mt-0 {
        margin-top: 0;
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .v1_about_us_img_card1 {
      left: 10px;
      top: 75px;
      height: 216px;
      width: 160px;
      padding: 16px;
    }

    .v1_about_us_img_card2 {
      height: 216px;
      width: 160px;
      padding: 16px;
    }

    .v1_about_us_img1 {
      height: 135px;
    }
    .v1_about_us_img2 {
      height: 135px;
    }

    .v1_about_us_img1_shapes_left {
      .shape_1 {
        height: 12px;
        width: 88px;
      }
    }
    .v1_about_us_img1_shapes_right {
      .shape_3 {
        height: 23px;
        width: 23px;
      }
    }

    .about_us_text_card {
      padding: 27px;
      h3 {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        font-size: 20px;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .v1_about_us_img_card1,
    .v1_about_us_img_card2 {
      position: static;
    }

    .v1_about_us_left_sect {
      margin: 50px 0;
    }

    .about_us_text_card {
      margin-bottom: 30px;

      p {
        max-width: 100%;
      }
    }

    .imageContainer {
      display: flex;
      img {
        width: 175px; // you can adjust this percentage to your liking
        height: auto; // maintain the aspect ratio
        margin-bottom: 50px;
        margin-right: 10px;
      }
    }
  }

  @media only screen and (max-width: 575px) {
    .v1_about_us_right_text {
      margin-top: 10px;
      p {
        font-size: 20px;
        line-height: 24px;
        padding-bottom: 10px;
      }
    }

    .v1_about_us_left_sect {
      max-width: 400px;
      margin: auto;
      min-height: 345px;
    }

    .v1_about_us_img_card1 {
      left: 10%;
      top: 75px;
      height: 216px;
      width: 160px;
      padding: 16px;
    }

    .v1_about_us_img_card2 {
      right: 10%;
      height: 216px;
      width: 160px;
      padding: 16px;
    }

    .v1_about_us_img1 {
      height: 135px;
    }
    .v1_about_us_img2 {
      height: 135px;
    }

    .v1_about_us_img1_shapes_left {
      .shape_1 {
        height: 12px;
        width: 88px;
      }
    }

    .v1_about_us_img1_shapes_right {
      .shape_3 {
        height: 23px;
        width: 23px;
      }
    }

    .v1_about_us_img2_shapes_left {
      .shape_1 {
        height: 12px;
        width: 88px;
      }
    }

    .v1_about_us_img2_shapes_right {
      .shape_3 {
        height: 23px;
        width: 23px;
      }
    }
  }

  @media (max-width: 1200px) {
    padding-top: 40px;
    .v1_about_us_right_sect {
      padding: 14px;
      width: 80%;
    }

    .v1_about_us_right_sect p {
      font-size: 30px;
      line-height: 30px;
    }
    .v1_about_us_left_sect {
      height: 350px;
    }
    .v1_about_us_img_card1 {
      left: 15px;
    }
    .v1_about_us_img_card2 {
      right: 15px;
      top: 245px;
    }

    .about_us_text_card {
      h3 {
        font-size: 20px;
        line-height: 40px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        span {
          max-width: 40px;
        }
      }
    }

    .imageContainer {
      display: flex;
      img {
        width: 150px; // you can adjust this percentage to your liking
        height: auto; // maintain the aspect ratio
        margin-bottom: 50px;
        margin-right: 10px;
      }
    }
  }
`;

export default AboutStyleWrapper;
