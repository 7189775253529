import {
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
  FaTelegramPlane,
  FaFacebook,
  FaMailchimp,
  FaEnvelope,
} from "react-icons/fa";

import openseaIcon from "../images/icon/opensea.svg";
import mediumIcon from "../images/icon/med.svg";

const data = [
  {
    icon: <FaTwitter />,
    url: "https://twitter.com/Blinky_Solana",
  },
  {
    icon: <FaEnvelope />,
    url: "mailto:support@blinky.xyz",
  },
  {
    icon: <FaTelegramPlane />,
    url: "https://t.me/blinkysol",
  },
  {
    thumb: mediumIcon,
    url: "#",
  },
];

export default data;
