import SectionTitle from "../../../../common/sectionTitle";
import FeaturesCard from "./featuresCard/FeaturesCard";
import FeaturesStyleWrapper from "./FeaturesD2.style";
import data from "../../../../assets/data/featuresDataD1";
import FeaturesCard2 from "./featuresCard/FeaturesCard2";

const FeaturesD2 = () => {
  return (
    <FeaturesStyleWrapper className="v2_about_us_section" id="tokenomics">
      <div className="v2_about_overlay"></div>
      <div className="container">
        <div style={{ textAlign: "center" }}>
          <SectionTitle title="Tokenomics" />
        </div>
        <div className="row features_items_row">
          {data.map((item, i) => (
            <div key={i} className="col-md-4">
              <FeaturesCard2 {...item} />
            </div>
          ))}
        </div>
      </div>
    </FeaturesStyleWrapper>
  );
};

export default FeaturesD2;
