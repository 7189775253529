import aboutCardIcon1 from "../images/icon/phantom.svg";
import aboutCardIcon2 from "../images/icon/solon.svg";
import aboutCardIcon3 from "../images/logoset.png";

const data = [
  {
    icon: aboutCardIcon1,
    title: "Get Phantom Extension",
    text: "Download and install phantom on the appstore, or, alternatively if you're on desktop download and install the browser extension.",
  },
  {
    icon: aboutCardIcon2,
    title: "Secure Your SOL ",
    text: "Now all you've got to do is buy your SOL in the PHANTOM APP, or, alternatively you can use an exchange and deposit using your wallet address.",
  },
  {
    icon: aboutCardIcon3,
    title: "Buy Some $BLINKY",
    text: "Head over to the Blinky Presale Page and secure your tokens through our fair launch process.",
    link: "https://www.pinksale.finance/solana/launchpad/78hBwGrfHMG1TcXNqXd3LmdUHsvTr6SNDJXZV75dUHSf",
  },
];

export default data;
