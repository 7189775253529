import GlobalStyles from "../assets/styles/GlobalStyles";
import Header from "../components/section/header/v1/Header";
import Layout from "../common/layout";
import Banner from "../components/section/banner/v1";
import Banner2 from "../components/section/banner/v2";
import About from "../components/section/about/v1";
import Footer from "../components/section/footer/v4";
import Features from "../components/section/features/v1/Features";
import FeaturesD2 from "../components/section/features/v1/FeaturesD2";
import Team from "../components/section/team/v1";

const HomeV1 = () => {
  return (
    <Layout>
      <GlobalStyles />
      <Header />
      <Banner />
      <About />
      <FeaturesD2 />
      <Banner2 />
      <Features />
      <Team />
      <Footer />
    </Layout>
  );
};

export default HomeV1;
