import { FaLinkedinIn, FaTwitter, FaInstagram } from "react-icons/fa";

import avatarThumb1 from "../../images/team/cmc.svg";
import avatarThumb2 from "../../images/team/coing.svg";
import avatarThumb3 from "../../images/team/wallets.svg";
import avatarThumb4 from "../../images/team/pink.svg";
import avatarThumb5 from "../../images/team/unknown1.svg";
import avatarThumb6 from "../../images/team/unknown2.svg";
import avatarThumb7 from "../../images/team/unknown3.svg";
import avatarThumb8 from "../../images/team/unknown4.svg";

const data = [
  {
    avatar: avatarThumb1,
    name: "CoinMarketCap",
    designation: "Listing Partner",
  },
  {
    avatar: avatarThumb2,
    name: "CoinGecko",
    designation: "Listing Partner",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
      {
        icon: <FaInstagram />,
        url: "#",
      },
    ],
  },
  {
    avatar: avatarThumb3,
    name: "Wallet Swap",
    designation: "Member",
    url: "https://wswap.site/",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
      {
        icon: <FaInstagram />,
        url: "#",
      },
    ],
  },
  {
    avatar: avatarThumb4,
    name: "Pinksale",
    designation: "Presale Partner",
    url: "https://www.pinksale.finance/solana/launchpad/78hBwGrfHMG1TcXNqXd3LmdUHsvTr6SNDJXZV75dUHSf",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
      {
        icon: <FaInstagram />,
        url: "#",
      },
    ],
  },
  {
    avatar: avatarThumb5,
    name: "Coming Soon",
    designation: "Exchange Partner",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
      {
        icon: <FaInstagram />,
        url: "#",
      },
    ],
  },
  {
    avatar: avatarThumb6,
    name: "Coming Soon",
    designation: "Exchange Partner",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
      {
        icon: <FaInstagram />,
        url: "#",
      },
    ],
  },
  {
    avatar: avatarThumb7,
    name: "Coming Soon",
    designation: "Exchange Partner",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
      {
        icon: <FaInstagram />,
        url: "#",
      },
    ],
  },
  {
    avatar: avatarThumb8,
    name: "Coming Soon",
    designation: "Exchange Partner",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
      {
        icon: <FaInstagram />,
        url: "#",
      },
    ],
  },
];

export default data;
