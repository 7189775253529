import { useState } from "react";
import { useModal } from "../../../../utils/ModalContext";
import {
  FaDiscord,
  FaEnvelope,
  FaTelegram,
  FaTwitter,
  FaWallet,
} from "react-icons/fa";
import { BsXLg } from "react-icons/bs";
import Button from "../../../../common/button";
import logo from "../../../../assets/images/logoset.png";
import { isMetaMaskInstalled } from "../../../../config";
import MobileMenuStyleWrapper from "./MobileMenu.style";

const MobileMenu = ({ mobileMenuhandle }) => {
  const {
    walletModalHandle,
    metamaskModalHandle,
    account,
    disconnectWalletFromApp,
  } = useModal();
  const [isSubmenu, setSubmenu] = useState(false);
  const isAirdropActive = false;

  const handleSubmenu = () => {
    setSubmenu(!isSubmenu);
  };

  const substr = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) : str;
  };

  const handleWalletConnect = async () => {
    if (!isMetaMaskInstalled()) {
      metamaskModalHandle();
    } else {
      walletModalHandle();
    }
  };
  return (
    <MobileMenuStyleWrapper className="bithu_mobile_menu">
      <div className="bithu_mobile_menu_content">
        <div className="mobile_menu_logo">
          <img className="bithu_logo" src={logo} alt="bithu logo" />
          <button
            className="mobile_menu_close_btn"
            onClick={() => mobileMenuhandle()}
          >
            {" "}
            <BsXLg />{" "}
          </button>
        </div>
        <div className="bithu_mobile_menu_list">
          <ul>
            <li className="mobile_menu_hide">
              <a href="#home" onClick={mobileMenuhandle}>
                🏠 &nbsp;Home
              </a>
            </li>
            <li className="mobile_menu_hide">
              <a href="#about" onClick={mobileMenuhandle}>
                ℹ️ &nbsp;About
              </a>
            </li>
            {isAirdropActive && (
              <li className="mobile_menu_hide">
                <a href="#airdrop" onClick={mobileMenuhandle}>
                  🪂 &nbsp;Airdrop
                </a>
              </li>
            )}

            <li className="mobile_menu_hide">
              <a href="#tokenomics" onClick={mobileMenuhandle}>
                💰 &nbsp;Tokenomics
              </a>
            </li>
            <li className="mobile_menu_hide">
              <a href="#our-partner" onClick={mobileMenuhandle}>
                🟡 &nbsp;Our Partners
              </a>
            </li>
            <li className="mobile_menu_hide">
              <a href="#buy" onClick={mobileMenuhandle}>
                🗺️ &nbsp;Buy
              </a>
            </li>
            <li className="mobile_menu_hide">
              <a href="#social" onClick={mobileMenuhandle}>
                👥 Social
              </a>
            </li>
          </ul>
        </div>
        <div className="mobile_menu_social_links">
          <a
            href="https://twitter.com/Blinky_Solana"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FaTwitter />
          </a>
          <a
            href="https://t.me/blinkysol"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FaTelegram />
          </a>
          <a
            href="mailto:support@blinky.xyz"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FaEnvelope />
          </a>
        </div>
      </div>
    </MobileMenuStyleWrapper>
  );
};

export default MobileMenu;
