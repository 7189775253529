import shape1 from "../../images/icon/star_1.svg";
import shape2 from "../../images/icon/star_2.svg";
import shape3 from "../../images/icon/star_3.svg";
import shape4 from "../../images/icon/star_4.svg";
import shape5 from "../../images/icon/star_5.svg";
import shape6 from "../../images/icon/star_6.svg";
import shape7 from "../../images/icon/star_7.svg";
// import shape8 from "../../images/icon/star_8.png";
// import shape9 from "../../images/icon/star_9.png"; 

const data = [
  shape6,
  shape3,
  shape1,
  shape7,
  shape4,
  shape2,
  shape5,
  shape2,
  shape3,
  shape1,
  shape7
];

export default data;
