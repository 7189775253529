import aboutCardIcon1 from "../../images/icon/aboutus_card_icon1.png";
import aboutCardIcon2 from "../../images/icon/aboutus_card_icon2.png";
import aboutCardIcon3 from "../../images/icon/aboutus_card_icon3.png";

const data = {
  aboutDescription1: "",
  aboutDescription2:
    "From the masterminds behind WalletSwap (100X gains), get ready for Blinky: the first pretty lady doggo sparking feminine trends on Solana! Determined to carve out a spot for women in the crypto space on Solana. \n\n She stands as a vibrant symbol of empowerment and innovation in a digital world thirsty for change.",
  aboutInfo: [
    {
      icon: aboutCardIcon1,
      title: "Multiple Wallets",
      text: "Easily create and manage multiple wallets within Blinky for seamless organization of your digital assets.",
    },
    {
      icon: aboutCardIcon2,
      title: "Built-in Web3 Browser",
      text: "Interact with dApps using our state-of-the-art web3 browser, designed for optimal convenience and security.",
    },
    {
      icon: aboutCardIcon3,
      title: "Real-time Graphs",
      text: "Stay updated on the market with real-time graphs across various time frames, making informed decisions easier than ever.",
    },
  ],
};

export default data;
