import contract from "../contracts/bithuabi.json";
import { ethers } from "ethers";
import { isMetaMaskInstalled, ethereum } from "../config";

export const mint = async (mint_amount) => {
  if (isMetaMaskInstalled()) {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const contractAddress = "0x9FD56e423c9f0C01D4d011ad30860962ddaeA51D";
    const nftContract = new ethers.Contract(contractAddress, contract, signer);
    let txnHash = await nftContract.mint(
      ethereum.selectedAddress,
      mint_amount,
      {
        gasLimit: "285000",
        value: ethers.utils.parseEther((0.03 * mint_amount).toString()),
      }
    );
    return txnHash;
  }
};

export const totalMintCount = async () => {
  if (isMetaMaskInstalled()) {
    return 0;
  }
};
