import React, { useEffect, useState } from "react";
import { formatDistanceToNowStrict } from "date-fns";
import "./TimerSmall.css";

function CountdownTimer() {
  const calculateTimeLeft = () => {
    let timeLeft = new Date("2023-07-30T17:30:00") - new Date();

    // Convert the time difference into days, hours, minutes, seconds
    let days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    let hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
    let minutes = Math.floor((timeLeft / 1000 / 60) % 60);
    let seconds = Math.floor((timeLeft / 1000) % 60);

    return { days, hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  return (
    <div className="timer-container">
      <div>{timeLeft.days}D :</div>
      <div> {timeLeft.hours}H :</div>
      <div> {timeLeft.minutes}M :</div>
      <div> {timeLeft.seconds}S </div>
    </div>
  );
}

export default CountdownTimer;
