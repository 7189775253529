import aboutCardIcon1 from "../images/icon/blinky_supply.svg";
import aboutCardIcon2 from "../images/icon/blink_support.svg";
import aboutCardIcon3 from "../images/icon/blinky_tax.svg";
import aboutCardIcon4 from "../images/icon/supply.png";
import aboutCardIcon5 from "../images/icon/mtax.png";
import aboutCardIcon6 from "../images/icon/dev.png";

const data = [
  {
    icon: aboutCardIcon1,
    title: "Total Supply",
    text: "500 Million supply on SOL - Lower fees, more access.",
  },
  {
    icon: aboutCardIcon2,
    title: "Network: Solana",
    text: "Runs on Solana for secure & quick transactions.",
  },
  {
    icon: aboutCardIcon3,
    title: "Tax: 0%",
    text: "Pure trading freedom. No deduction.",
  },
];

export default data;
