import SectionTitle from "../../../../common/sectionTitle";

import AboutInfoCardList from "../aboutInfoCardList";

import data from "../../../../assets/data/about/aboutv1";
import aboutThumb1 from "../../../../assets/images/nft/about_us_img1.png";
import aboutThumb2 from "../../../../assets/images/nft/about_us_img2.png";
import AboutStyleWrapper from "./About.style";
import logo from "../../../../assets/images/logox.png";

const About = () => {
  const { aboutDescription1, aboutDescription2 } = data;
  return (
    <AboutStyleWrapper eWrapper id="about">
      <div className="container">
        <div className="row flex-row-reverse d-flex justify-content-center">
          <div className="v1_about_us_right_sect">
            <SectionTitle />
            <div className="logo">
              <a href="/">
                <img src={logo} alt="bithu nft logo" />
              </a>
            </div>
            <div className="v1_about_us_right_text">
              <p style={{ color: "#FFE5B4" }}>{aboutDescription2}</p>
            </div>
          </div>
        </div>
      </div>
    </AboutStyleWrapper>
  );
};

export default About;
