import React from "react";
import FeaturesCardStyleWrapper from "./FeaturesCard.style";

const FeaturesCard = ({ title, icon, text, link }) => {
  // Conditionally render content with or without an anchor tag
  const cardContent = (
    <>
      <h3>
        <img src={icon} alt="" />
        {title}
      </h3>
      <p>{text}</p>
    </>
  );

  return (
    <FeaturesCardStyleWrapper className="about_us_text_card">
      {link ? (
        <a href={link} style={{ textDecoration: "none", color: "inherit" }}>
          {cardContent}
        </a>
      ) : (
        cardContent
      )}
    </FeaturesCardStyleWrapper>
  );
};

export default FeaturesCard;
