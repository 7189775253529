import { Routes, Route } from "react-router-dom";
import HomeV1 from "../pages/homeV1";
import Collections from "../pages/collections";
import MintPageOne from "../pages/mint-1";
import PrivacyX from "../pages/PrivacyDetails";
import HomeV3Airdrop from "../pages/homeV3Airdrop";

function App() {
  const isAirdropActive = true;

  return (
    <Routes>
      <Route path="/" element={<HomeV1 />} exact />
    </Routes>
  );
}

export default App;
