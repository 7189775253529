import styled from "styled-components";
import sectionBgImage from "../../../../assets/images/bg/blinky_banner_4.png";

const FeaturesStyleWrapper = styled.section`
  background: url(${sectionBgImage});
  padding-top: 132px;
  padding-bottom: 100px;
  min-height: 950px;
  width: 100%;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 200px;
  .features_items_row {
    row-gap: 30px;
    margin-top: 150px;
  }
  @media (max-width: 768px) {
    .features_items_row {
      row-gap: 50px;
      margin-top: 60px;
    }

    .features_items_row .col-md-4 {
      row-gap: 50px;
      margin-top: 60px;
    }
  }

  @media (max-width: 575px) {
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 100px;
    padding-bottom: 80px;
  }
`;

export default FeaturesStyleWrapper;
