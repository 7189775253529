import styled from "styled-components";

const ContactStyleWrapper = styled.section`
  padding: 85px 0 140px;
  background-color: #02060e;
  .input-container {
    position: relative;
  }

  .widget_title_info {
    margin-top: 100px;
    margin-bottom: 25px;
    text-align: center;
  }

  .input-container .verify-button:disabled {
    opacity: 0.5;
  }

  // CSS for message
  .message {
    margin-top: 10px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .spinner {
    animation: spin 2s linear infinite;
  }

  .success {
    color: #ff69b4;
  }

  .failure {
    color: crimson;
  }

  .input-container input {
    padding-right: 60px; /* adjust this value based on the size of your button */
  }

  .verify-button {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100px;
    height: 100%;
    background: rgba(128, 128, 128, 0.3); /* grey color with 70% opacity */
    color: white;
    border: none;
  }

  .section_tag_line {
    h4 {
      text-align: center;
      color: #ff69b4;
      margin-bottom: 13px;
    }
    h2 {
      text-align: center;

      font-size: 48px;
      line-height: 67px;
      color: #ffffff;
      margin-bottom: 37px;
    }
  }

  .left_content {
    form {
      .form_box + .form_box {
        margin-top: 51px;
      }
    }
  }

  .join_btn {
    height: 50px;
    width: 100%;
    padding-left: 10px;
    background-color: transparent;
    /* Adjust this value to your liking */
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  label {
    font-family: "Bubblegum Sans", sans-serif;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.8);
    line-height: 10px;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 5px;
  }

  input,
  textarea {
    border: 2px solid rgba(255, 255, 255, 0.1);
    background: transparent;
    outline: none;
    padding: 8px 21px;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    width: 100%;
    box-sizing: border-box;
  }

  input {
    height: 60px;
  }

  textarea {
    height: 130px;
    resize: none;
  }

  .bithu-btn {
    margin-top: 35px;
  }

  .right_content {
    padding-left: 110px;

    h4,
    h5 {
      color: #ffffff;
      text-transform: uppercase;
    }
    h5 {
      font-size: 18px;
      margin-bottom: 6px;
    }

    p {
      font-size: 16px;
      line-height: 25px;
      font-weight: 400;
      margin-bottom: 0;
    }

    .widget_title {
      margin-bottom: 25px;
    }

    .contact_address_info {
      display: flex;
      align-items: baseline;
      column-gap: 15px;
    }

    .contact_address_info + .contact_address_info {
      margin-top: 15px;
    }
  }

  @media only screen and (max-width: 991px) {
    .section_tag_line {
      h4 {
        font-size: 22px;
      }
      h2 {
        font-size: 38px;
        line-height: 50px;
      }
    }

    .right_content {
      padding-left: 40px;

      p {
        font-size: 14px;
        line-height: 30px;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .section_tag_line {
      h4 {
        font-size: 20px;
      }
      h2 {
        font-size: 34px;
        line-height: 45px;
      }
    }

    .right_content {
      margin-top: 150px;
      padding-left: 0px;
    }
  }

  @media only screen and (max-width: 400px) {
    padding: 14px;

    input {
      font-size: 14px;
    }

    button {
      font-size: 14px;
    }

    verify-button {
      max-width: 50px;
    }

    .platformText {
      font-size: 14px;
    }
  }
`;

export default ContactStyleWrapper;
