import styled, { keyframes } from "styled-components";
import sectionBgImage from "../../../../assets/images/bg/blinky_banner_2.png";

const rotate = keyframes`
from {
  transform: rotate(0deg);
}

to {
  transform: rotate(360deg);
}
`;

const BannerV1Wrapper = styled.section`
  background: url(${sectionBgImage});
  min-height: 950px;
  width: 100%;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 200px;

  @keyframes float {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0px);
    }
  }

  .bithu_v1_baner_left {
    animation: float 3s ease-in-out infinite; /* adjust time (3s here) to your liking */
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    textalign: center;
    max-width: 500px;
    border-radius: 50px; /* adjust this value to get the desired bubble shape */
    padding: 20px; /* adjust padding to your liking */
    h2 {
      font-family: "Bubblegum Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 60px;
      line-height: 80px;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: px;
    }
    h3 {
      margin-top: 30px;
      margin-bottom: 30px;
      font-family: "Bubblegum Sans", sans-serif;
      font-weight: 700;
      font-size: 30px;
      line-height: 40px;
      text-align: center;
      color: #ff69b4; /* Pink */
      letter-spacing: 1px; /* Increased letter spacing for a stylish look */
      padding: 10px; /* Adds padding around the text */
      background-color: rgba(
        255,
        255,
        255,
        0.5
      ); /* Semi-transparent white background */
      display: inline-block; /* Allows the background to fit the text */
      border-radius: 8px; /* Optional: Adds rounded corners to the background */
    }

    h5 {
      color: #ffffff;
    }

    .banner_buttons {
      display: flex;
      column-gap: 30px;
      margin-top: 58px;
    }

    .imageContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 200px; // you can adjust this percentage to your liking
        height: auto; // maintain the aspect ratio
        margin-right: 10px;
      }
    }

    .coin-info {
      display: flex;
      flex-direction: column;
      margin-top: 37px;
      span {
        font-family: "Bubblegum Sans", sans-serif;
        font-size: 18px;
        line-height: 36px;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.8);

        & .highlighted {
          color: #ffe600;
        }
      }
    }
  }

  .contract-address-container {
    width: fit-content;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 15px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    textalign: center;
  }

  .bithu_v1_baner_right {
    max-width: 500px;
    width: 100%;
    margin-left: auto;

    .bithu_v1_baner_right_img_sect {
      position: relative;
      width: 100%;
      height: 550px;
      display: flex;
      align-items: center;
      justify-content: center;

      .bithu_v1_baner_right_img_bg {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .bithu_v1_baner_right_img {
        position: relative;
        z-index: 1;
      }
    }
  }

  @media only screen and (max-width: 1002px) {
    .bithu_v1_baner_left {
      margin-top: 0;
      margin-bottom: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      h2 {
        font-size: 45px;
      }
      h3 {
        font-size: 28px;
      }
      img {
        max-width: 200px;
      }
      Button {
        max-width: 200px;
      }
      min-height: 400px;
    }

    .bithu_v1_baner_right {
      padding-left: 0px;
      padding-bottom: 50px;

      .mint_live_circle_sect {
        height: 90px;
        width: 90px;
        top: -36px;
        left: -40px;
      }
    }

    .bithu_v1_baner_right_img {
      display: flex;
      justify-content: center;
    }
  }

  @media only screen and (max-width: 502px) {
    .bithu_v1_baner_left {
      padding: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      h2 {
        font-size: 35px;
      }
      h3 {
        font-size: 22px;
      }
      img,
      Button {
        max-width: 200px;
      }
      min-height: 400px;
    }

    .imageContainer {
      flex-direction: column;
      img {
        margin-bottom: 10px;
        margin-right: 0px;
      }
    }

    .contract-address-container h3 {
      font-size: 14px;
      font-family: "Bubblegum Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 60px;
      line-height: 80px;
      color: #ffffff;
    }

    .bithu_v1_baner_right {
      padding-left: 0px;
      padding-bottom: 50px;

      .mint_live_circle_sect {
        height: 90px;
        width: 90px;
        top: -36px;
        left: -40px;
      }
    }

    .bithu_v1_baner_right_img {
      display: flex;
      justify-content: center;
    }
  }

  @media (max-width: 360px) {
    .bithu_v1_baner_left {
      .coin-info {
        font-size: 13px;
      }
    }

    .contract-address-container h3 {
      font-size: 14px;
      font-family: "Bubblegum Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 60px;
      line-height: 80px;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: px;
    }
  }
`;

export default BannerV1Wrapper;
